import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navigation from "../Common/navigation";
import User from "../../helpers/login/userData";
import Auth from "../../helpers/login/auth";
import { useDispatch, useSelector } from "react-redux";
import { Badge, NavDropdown } from "react-bootstrap";
import { logout, replaceAcountData } from "../../store/my-account-actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import config from "../../config";
import { toast } from "react-toastify";

function NavigationUK(props) {
  const [isToggled, setIsToggled] = useState(false);
  const location = useLocation();

  const handleClick = () => {
    setIsToggled(!isToggled);
  };
  //console.log("navi", props.data);
  const auth = Auth();
  const user = User();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  //console.log("PROPS HEADER", user);
  const totalItems = useSelector((state) => state.cart.totalItems);
  const logoutClick = (e) => {
    toast.success("User logged out successfully!", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    dispatch(logout());
    dispatch(replaceAcountData());
    window.localStorage.removeItem("user");
    // navigate("/login");
    window.location.reload();
  };
  const welcomeUserDropdown = (
    <>
      <span
        className={
          config.project === "insurance"
            ? "text-light fw-bold"
            : props?.data?.navigationColor
        }
      >
        <FontAwesomeIcon icon={faUser} /> <b>{user?.firstName}</b>
      </span>
    </>
  );

  return (
    <div className={`uk-header ${props.dataClass} pt-2`}>
      <div className="container">
        <div className="row w-100 uk-nav p-0">
          <div className="uk-logo col-md-8 d-flex flex-row">
            <a href="/home">
              <img
                src="/images/publicsector/logo-uk-white.png"
                alt="AgentX Logo"
              />
            </a>
          </div>

          <div className="uk-menu col-md-2">
            <div className="link">
              <a className={isToggled ? "active" : ""} onClick={handleClick}>
                {isToggled ? (
                  <i className="m-2 fa-solid fa-chevron-up"></i>
                ) : (
                  <i className="m-2 fa-solid fa-chevron-down"></i>
                )}
                <span className="text-white">Menu</span>
              </a>
            </div>
          </div>

          <div className="uk-search col-md-1 pt-2">
            <i className="text-white fa-xl fa-sharp fa-solid fa-magnifying-glass"></i>
          </div>
          <div className="uk-user col-md-1 pt-2">
            {!auth && (
              <a href="/login" className="text-light py-2 ">
                <span className={` text-user `}>Login</span>
              </a>
            )}
            {auth && user && (
              <NavDropdown title={welcomeUserDropdown} menuVariant="light">
                <NavDropdown.Item onClick={logoutClick}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </div>
        </div>
      </div>

      {isToggled && (
        <div className="uk-gray uk-drop-menu">
          <div className=" container">
            <div className=" row p-4">
              <div className="col-sm-12 col-md-8 d-inline flex-column mt-4">
                <div className=" row ">
                  <h4 className="px-2">{props.data[0]?.title}</h4>
                  {props.data[0]?.items &&
                    props.data[0]?.items?.map(
                      (item, index) =>
                        !item.restricted && (
                          <div className="col-sm-12 col-md-6 item flex-column d-flex mb-2 p-0">
                            <a
                              key={index}
                              href={item?.url ? item.url : "/"}
                              className="d-inline-block w-100 px-2 py-0 "
                              onClick={() => {
                                window.analytics.track("clicked " + item.name, {
                                  title: item.name,
                                });
                              }}
                            >
                              {item?.name && (
                                <span
                                  className={`${
                                    location?.pathname?.includes(
                                      item?.url + "/"
                                    ) || location?.pathname === item?.url
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  {item.name}
                                </span>
                              )}
                            </a>{" "}
                            <span className="px-2">{item.description}</span>
                          </div>
                        )
                    )}
                </div>
              </div>

              <div className="col-sm-12 col-md-4 d-flex flex-column mt-4">
                <h4 className="px-2">{props.data[1]?.title}</h4>
                {props.data[1]?.items &&
                  props.data[1]?.items?.map((item, index) => (
                    <div className="item flex-column d-flex mb-2">
                      <a
                        key={index}
                        href={item?.url ? item.url : "/"}
                        className="px-2 py-0 w-100"
                        onClick={() => {
                          window.analytics.track("clicked " + item.name, {
                            title: item.name,
                          });
                        }}
                      >
                        {item?.name && (
                          <span
                            className={`${
                              location?.pathname?.includes(item?.url + "/") ||
                              location?.pathname === item?.url
                                ? "active"
                                : ""
                            }`}
                          >
                            {item.name}
                          </span>
                        )}
                      </a>
                      <span className="px-2">{item.description}</span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NavigationUK;
