import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import Navigation from "../Common/navigation";
import User from "../../helpers/login/userData";
import Auth from "../../helpers/login/auth";
import { useDispatch, useSelector } from "react-redux";
import { Badge, NavDropdown } from "react-bootstrap";
import { logout, replaceAcountData } from "../../store/my-account-actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import config from "../../config";
import { toast } from "react-toastify";
/**
 *
 * @Container props
 * logo: Website Logo image
 * data.items[]: array of {url, name} -> for navigation links
 */
const Header = (props) => {
  const auth = Auth();
  const user = User();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  //console.log("PROPS HEADER", user);
  const totalItems = useSelector((state) => state.cart.totalItems);
  const logoutClick = (e) => {
    toast.success("User logged out successfully!", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    dispatch(logout());
    dispatch(replaceAcountData());
    window.localStorage.removeItem("user");
    // navigate("/login");
    window.location.reload();
  };
  const welcomeUserDropdown = (
    <>
      <span
        className={
          config.project === "insurance"
            ? "text-light fw-bold"
            : props?.data?.navigationColor
        }
      >
        <FontAwesomeIcon icon={faUser} /> <b>{user?.firstName}</b>
      </span>
    </>
  );

  return (
    config.project !== "publicsector" && (
      <header className={`header px-5  ${props.data?.headerClassName}`}>
        <Navbar expand="lg">
          <div className="row w-100 align-items-center">
            <Navbar.Brand className=" col-lg-3 col-6 p-0 m-0 ">
              <a href="/home">
                {props?.logo && (
                  <img
                    src={props.logo}
                    alt=""
                    className={
                      props?.data?.logoClassname ? props.data.logoClassname : ""
                    }
                  />
                )}
              </a>
            </Navbar.Brand>

            {props?.data && props.data.navigations && (
              <Navigation data={props.data.navigations} />
            )}

            {config?.project === "finance" && (
              <>
                {!auth && (
                  <a
                    href="/login"
                    className="col-lg-2 offset-lg-1 col-md-2 offset-md-10 col-2 offset-10  py-2 "
                  >
                    <span
                      className={` text-uppercase login-header ${props?.data?.navigationColor}`}
                    >
                      Login
                    </span>
                  </a>
                )}
                {auth && user && (
                  <div className="logout col-lg-2 offset-lg-1 col-md-2 offset-md-10 col-4 offset-8 py-2">
                    <NavDropdown
                      title={welcomeUserDropdown}
                      menuVariant="light"
                    >
                      <NavDropdown.Item onClick={logoutClick}>
                        Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                )}
              </>
            )}

            {/* {auth && user && (
          <div className="col-lg-2 offset-lg-1 col-md-2 offset-md-10 col-4 offset-8">
            <NavDropdown title={welcomeUserDropdown} menuVariant="light">
              <NavDropdown.Item onClick={logoutClick}>Logout</NavDropdown.Item>
            </NavDropdown>
          </div>
        )} */}
            {config?.project !== "finance" && (
              <div className="col-lg-2  col-md-12 row py-2 login-button navigation-header d-flex justify-content-between">
                {!auth && (
                  <a
                    href="/login"
                    className={`col-2 px-2 ${
                      config.project === "insurance"
                        ? " fw-bold"
                        : props?.data?.navigationColor
                        ? props?.data?.navigationColor
                        : "font-white"
                    } `}
                  >
                    <span className={` text-uppercase fw-bold`}>Login</span>
                  </a>
                )}

                {auth && user && (
                  <ul className=" d-flex  justify-content-between ">
                    <li>
                      {" "}
                      <NavDropdown
                        title={welcomeUserDropdown}
                        menuVariant="light"
                      >
                        <NavDropdown.Item onClick={logoutClick}>
                          Logout
                        </NavDropdown.Item>
                      </NavDropdown>
                    </li>
                    {config.project !== "insurance" &&
                      config.project !== "publicsector" && (
                        <li>
                          <a
                            href="/cart"
                            className={` px-2 ${
                              props?.data?.navigationColor
                                ? props?.data?.navigationColor
                                : "font-white cart-icon"
                            }`}
                          >
                            <span
                              className={`text-uppercase position-absolute ${
                                props?.data?.navigationColor
                                  ? props?.data?.navigationColor
                                  : props?.data?.navigationColor
                              }`}
                            >
                              {totalItems > 0 && (
                                <Badge bg="secondary cart-badge-header">
                                  {totalItems}
                                </Badge>
                              )}
                              <i className="fa-solid fa-cart-shopping fa-lg"></i>
                            </span>
                          </a>
                        </li>
                      )}
                  </ul>
                )}
              </div>
            )}
          </div>
        </Navbar>
      </header>
    )
  );
};

export default Header;
