import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { removeAnItem, clearCart } from "../store/cart-actions";
import axios from "axios";
import Config from "../config";
// @description :
//  Renders Industries and Platforms
//  Creates event for shortcut to activate modal
//  Executes selected verticals and platforms by storing data into localstorage
//  @PROPS:
//  component=[]
//
//  @author: BOBAN
//

// { JSON MODEL IN HERE}
const Switch = (props) => {
  Modal.setAppElement("#root");
  //FOR LOCALHOST use below
  const twilioChatWorkerApi = Config.twilioChatWorkerApi;
  const [phones, setPhones] = useState([]);
  const [phoneData, setPhoneData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activeModal, setActiveModal] = useState("");
  const [settings, setSettings] = useState([]);
  const dispatch = useDispatch();
  const [ukFlag, setUkFlag] = useState(false);
  const [PHFlag, setPHFlag] = useState(false);
  //reset all Cart data on project switch
  const clearAllDataInCart = () => {
    dispatch(clearCart());
  };
  const [staticProjectName, setStaticProjectName] = useState(
    window.localStorage.getItem("staticProjectName")
  );
  const [staticCallCenter, setStaticCallCenter] = useState(
    window.localStorage.getItem("staticCallCenter")
  );
  const [staticProjectDesc, setStaticProjectDesc] = useState(
    window.localStorage.getItem("staticProjectDesc")
  );

  document.onkeyup = function (e) {
    if (e.ctrlKey && e.shiftKey && e.which == 32) {
      handleOpenModal("switch");
    }
    //CTRL+SHIFT+8
    if (e.ctrlKey && e.shiftKey && e.which == 56) {
      handleOpenModal("switch");
    }
    //CTRL+SHIFT+t
    if (e.ctrlKey && e.which == 91 && e.which == 121) {
      handleOpenModal("switch");
    }
  };

  const callCenters = ukFlag
    ? [
        {
          name: "amz",
          phone: "18005776626",
          icon: "/images/chat/amazon.png",
          desc: "AWS Connect",
        },
      ]
    : PHFlag
    ? [
        {
          name: "amzph",
          phone: "18005776626",
          icon: "/images/chat/amazon.png",
          desc: "AWS Connect PH",
        },
        {
          name: "movph",
          phone: "13465368342",
          icon: "/images/chat/moveoprod.png",
          desc: "Chat AI PH",
        },
        {
          name: "twl",
          phone: "13465368342",
          icon: "/images/chat/twilio.png",
          desc: "Twilio PH",
        },
      ]
    : [
        {
          name: "twl",
          phone: "13465368342",
          icon: "/images/chat/twilio.png",
          desc: "Twilio",
        },
        {
          name: "amz",
          phone: "18005776626",
          icon: "/images/chat/amazon.png",
          desc: "AWS Connect",
        },
        {
          name: "mov",
          phone: "13465368342",
          icon: "/images/chat/moveo.png",
          desc: "Chat AI DEV",
        },
        {
          name: "movprod",
          phone: "13465368342",
          icon: "/images/chat/moveoprod.png",
          desc: "Chat AI PROD",
        },
        {
          name: "movten",
          phone: "13465368342",
          icon: "/images/chat/moveotenant.png",
          desc: "Chat AI Tenant",
        },
      ];

  let industries = [
    {
      name: "publicsector",
      phone: phoneData?.publicsector,
      icon: "fa-duotone fa-building-columns",
      desc: "Public Sector",
    },
    {
      name: "media",
      phone: phoneData?.media,
      icon: "fa-duotone fa-building-columns",
      desc: "Media Center Friends",
    },

    {
      name: "vet",
      phone: phoneData?.vet,
      icon: "fa-duotone fa-building-columns",
      desc: "Paw Friends",
    },
    {
      name: "finance",
      phone: phoneData?.banking,
      icon: "fa-duotone fa-building-columns",
      desc: "Bank Of Global",
    },
    {
      name: "retail",
      phone: phoneData?.retail,
      icon: "fa-duotone fa-cart-shopping",
      desc: "DesignLook",
    },
    {
      name: "insurance",
      phone: phoneData?.insurance,
      icon: "fa-duotone fa-car-burst",
      desc: "Mercurio",
    },
    {
      name: "travel",
      phone: phoneData?.travel,
      icon: "fa-duotone fa-cart-flatbed-suitcase",
      desc: "Traveleer",
    },
    {
      name: "telecom",
      phone: phoneData?.telco,
      icon: "fa-solid fa-wifi",
      desc: "Telcom",
    },
    {
      name: "auto",
      phone: phoneData?.auto,
      icon: "fa-solid fa-car",
      desc: "1000 Miles",
    },
    {
      name: "utility",
      phone: phoneData?.utility,
      icon: "fa-solid fa-car",
      desc: "Utility Friends",
    },
    {
      name: "energy",
      phone: phoneData?.energy,
      icon: "fa-solid fa-car",
      desc: "Green Energy",
    },
    {
      name: "environmental",
      phone: phoneData?.environmental,
      icon: "fa-solid fa-car",
      desc: "Environmental Managment",
    },
  ];
  /*Checking for domain anme if applies to UK to load correspondent AWS configuration
  Note: url must contain "-uk." in order to be recognize by domain name*/
  useEffect(() => {
    const checkUkDomain = () => {
      const url = window.location.hostname;
      if (url.includes("-uk.")) {
        setUkFlag(true);
      }
      if (url.includes("-ph.")) {
        setPHFlag(true);
      }
      console.log("window.location.hostname", window.location.hostname);
    };
    //DEV  and PROD use different instances of AWS so need different IDs

    checkUkDomain();
  }, [props]);

  const setNewVertical = () => {
    clearAllDataInCart();
    //clear up local storage and reload to pickup new project name
    window.localStorage.setItem("staticProjectName", staticProjectName);
    window.localStorage.setItem("staticProjectDesc", staticProjectDesc);
    window.localStorage.setItem("staticCallCenter", staticCallCenter);
    window.location.reload();
  };

  const handleOpenModal = (val) => {
    setActiveModal(val);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setActiveModal("");
  };

  useEffect(() => {
    if (!staticProjectName) {
      console.log(
        "Agent X DEMO is not set yet. Restarting to default Insurance"
      );
      let project = "finance";
      let projectDesc = "BankOfGlobal";
      let callcenter = "aws";

      window.localStorage.clear();

      //setting defaults
      window.localStorage.setItem(
        "staticCallCenter",
        JSON.stringify(callcenter)
      );
      //setting defaults
      window.localStorage.setItem("staticProjectName", JSON.stringify(project));
      window.localStorage.setItem(
        "staticProjectDesc",
        JSON.stringify(projectDesc)
      );

      window.location.reload();
    }
  }, [props]);

  useEffect(() => {
    console.log("SWITCH staticProjectName", staticProjectName);
    console.log("SWITCH staticProjectDesc", staticProjectDesc);
    console.log("SWITCH staticCallCenter", staticCallCenter);
    console.log("SWITCH phoneData", phoneData);
    console.log("SWITCH props", props);
    if (JSON.parse(staticCallCenter) === "amz") setPhoneData(phones.phonesAWS);
    if (JSON.parse(staticCallCenter) === "amzph")
      setPhoneData(phones.phonesAWS);
    if (JSON.parse(staticCallCenter) === "twl") setPhoneData(phones.phones);
    if (
      JSON.parse(staticCallCenter) === "mov" ||
      JSON.parse(staticCallCenter) === "movprod"
    )
      setPhoneData([]);
  }, [staticProjectName, staticCallCenter, phones]);

  useEffect(() => {
    const fetchphoneJson = async () => {
      try {
        const res = await axios.get(twilioChatWorkerApi);
        if (res.data) {
          setPhones(res.data);
        }
      } catch (e) {}
    };
    fetchphoneJson();
  }, []);

  return (
    <div className="switch-contaier">
      <Modal
        isOpen={showModal && activeModal.toString() === "switch"}
        contentLabel="login Modal"
      >
        <div className="content d-flex flex-column">
          <button
            className="close btn d-flex justify-content-end align-items-end"
            onClick={() => handleCloseModal()}
          >
            <i className="fa-regular fa-circle-xmark"></i>
          </button>

          <Form.Group as={Row} className="mb-3 demo">
            <Col sm={{ span: 12 }}>
              <div className="wrapper switch ribbon ribbon-top   p-2 rounded ">
                {industries.length > 0 &&
                  industries.map((industry, index) => {
                    return (
                      <button
                        key={index}
                        className={
                          JSON.parse(staticProjectName) === industry.name
                            ? "btn  btn-transparent switch shadow border active border me-2"
                            : "btn  btn-transparent p-2 switch border me-2"
                        }
                        onClick={() => {
                          setStaticProjectName(
                            JSON.stringify(industry.name.toLowerCase())
                          );
                          setStaticProjectDesc(JSON.stringify(industry.desc));
                        }}
                      >
                        <span className="d-flex flex-row  align-items-center w-100 font-dark fs-9">
                          <img
                            className="mw-100  hc-15 wc-15 p-1 bg-white rounded-circle "
                            alt={industry.name}
                            src={`/images/${industry.name}/logo-32.png`}
                          />{" "}
                          <span className=" d-flex flex-column align-items-start p-2   w-100  ">
                            <b
                              className={
                                JSON.parse(staticProjectName) === industry.name
                                  ? " fs-9  "
                                  : "  fs-9"
                              }
                            >
                              {industry.name}
                            </b>{" "}
                            <u> {industry?.phone}</u>
                          </span>
                        </span>

                        {/* {this.state.staticProjectName===industry.name?(<span className={ this.state.staticProjectName===industry.name?"ribbon-label bg-danger p-1":"ribbon-label bg-primary"} >selected</span>):null} */}
                      </button>
                    );
                  })}
              </div>
            </Col>
          </Form.Group>
          <hr />
          {/* <Form.Group as={Row} className="mb-1 demo">
            <Col sm={{ span: 12 }}>CCaS Chat service</Col>
          </Form.Group> */}
          <Form.Group as={Row} className="mb-3 demo  p-2 rounded  ">
            <Col sm={{ span: 12 }}>
              <div className="wrapper switch ribbon ribbon-top ">
                {callCenters.map((cc, index) => {
                  return (
                    <button
                      key={index}
                      className={
                        JSON.parse(staticCallCenter) === cc.name
                          ? "btn  btn-transparent switch shadow active border me-2"
                          : "btn  btn-transparent  switch border me-2"
                      }
                      onClick={() =>
                        setStaticCallCenter(
                          JSON.stringify(cc.name.toLowerCase())
                        )
                      }
                    >
                      <span className="d-flex flex-row  ">
                        {/* <i
                            className={`${cc.icon} mw-100 w-50 p-1 bg-white rounded`}
                          ></i>{" "} */}
                        <img
                          className="mw-100 w-25 h-25 p-1 bg-white rounded-circle"
                          alt={cc.name}
                          src={cc.icon}
                        />
                        <span className="d-flex flex-column align-items-start p-2   w-100  ">
                          <b className="    fs-9">{cc.name}</b>{" "}
                          <b className="    fs-9">{cc.desc}</b>{" "}
                        </span>
                      </span>

                      {/* {this.state.staticProjectName===industry.name?(<span className={ this.state.staticProjectName===industry.name?"ribbon-label bg-danger p-1":"ribbon-label bg-primary"} >selected</span>):null} */}
                    </button>
                  );
                })}
              </div>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="row mb-5 demo mt-4  p-3">
            <hr />
            <Col sm={{ span: 4 }} className="d-flex justify-content-center">
              {" "}
              <p>
                If this popup is non intentional you can click on cancel button
                to cencel Switch process.
              </p>
            </Col>
            <Col
              sm={{ span: 8 }}
              className="d-flex justify-content-center mb-3"
            >
              <button
                className="btn p-4 btn-primary me-2 shadow"
                onClick={() => setNewVertical()}
              >
                Apply selected: <b className="uppercase">{staticProjectName}</b>
                {" & "}
                <b className="uppercase">{staticCallCenter}</b>
              </button>

              <button
                className="btn btn-secondary me-2 wc-15 "
                onClick={() => handleCloseModal()}
              >
                Cancel
              </button>
            </Col>
            <hr />
          </Form.Group>
        </div>
      </Modal>
    </div>
  );
};
export default Switch;
