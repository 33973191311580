import { Row } from "react-bootstrap";
import CatalogItemCard from "../CatalogItemCard";
import CardSingle from "./card.single";
/**
 *  @Component props.data:
 * title
 * componentContent
 * cards[]
 */
const CardContainerBanner = (props) => {
  console.log("CardContainerBanner", props);
  const class6 = "col-xs-12 col-md-6";
  const class3 = "col-xs-12 col-md-3";
  const class4 = "col-xs-12 col-md-4 bg-light";

  let index0 =
    props?.data[0].model === "6-3-3"
      ? class6
      : props?.data[0].model === "6-6-6"
      ? class6
      : class3;
  let index1 = props?.data[0].model === "6-3-3" ? class3 : class6;
  let index2 = props?.data[0].model === "6-3-3" ? class3 : class3;
  let index3 = props?.data[0].model === "6-6-6" ? class6 : class6;
  if (props?.data[0].model === "4-4-4") {
    index0 = class4;
    index1 = class4;
    index2 = class4;
  }

  console.log("index", props?.data[0].model);
  console.log("index0", index0);
  console.log("index1", index1);
  console.log("index2", index2);
  console.log("index3", index3);
  return (
    props.data &&
    props.data?.map((cardset, index) => (
      <div
        key={index}
        className={
          cardset.className
            ? cardset.className + " row component card-container-banner"
            : " row component card-container-banner"
        }
      >
        {cardset.title && <h2>{cardset.title}</h2>}
        {cardset.componentContent && (
          <div className="mt-2 mb-2 text-center">
            {cardset.componentContent}
          </div>
        )}
        <div className="row">
          {cardset.columns &&
            cardset.columns.map((column, index) => {
              return (
                <div
                  key={index}
                  className={`${
                    index === 0 ? index0 : index === 1 ? index1 : index2
                  }    `}
                >
                  {column.cards?.map((card, idx) => {
                    return (
                      <CardSingle
                        className="col-xs-12 single-card h-auto"
                        data={card}
                        key={idx}
                      />
                    );
                  })}
                </div>
              );
            })}
        </div>
      </div>
    ))
  );
};

export default CardContainerBanner;
