/*
@Component props "links" array with  :
    style,
    title
    items{
      name,
      url
    }  
    
*/

import { Link } from "react-router-dom";

const LinksContainer = (props) => {
  const { data } = props;
  return (
    <div className={`row component links-component  ${data.model}`}>
      {data.title && <h2>{data.title}</h2>}
      {data.items.map((link, idx) => (
        <a
          key={idx}
          href={link.url ? link.url : "/"}
          className={`animate__animated animate__zoomInDown ${
            data?.align === "col" ? "col-12" : "col"
          } ${data?.style ? data.style : ""}`}
        >
          {link.faicon && <i className={link.faicon}></i>}
          <span>
            {link.name && <span>{link.name}</span>}
            {link.description && <i>{link.description}</i>}
          </span>
          {link.sideIcon && <i className={link.sideIcon}></i>}
        </a>
      ))}
    </div>
  );
};
export default LinksContainer;
