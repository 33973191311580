/**
 *
 * @Container props.data
 * className: css classes for parent container
 * data{
 * img,
 * title,
 * blurb,
 * buttonText,
 * buttonLink }
 */

import NavigatioUK from "./navigationUK.js";

const parse = require("html-react-parser");

const BannerUK = (props) => {
  const {
    img,
    imgClass,
    title,
    blurb,
    buttonText,
    buttonLink,
    textClass,
    className,
    navigations,
    breadcrumbs,
  } = props.data;

  return (
    <section
      style={{
        background: `url(${img}) center center / cover`,
        // backgroundSize: `cover`,
        // backgroundPosition: `center`,
      }}
      className={`banner ${imgClass ? imgClass : ""} ${
        className ? className : ""
      }`}
    >
      <NavigatioUK
        data={navigations}
        dataClass={textClass ? textClass : "uk-blue"}
      />{" "}
      <div className={`banner-content ${textClass ? textClass : ""}`}>
        <div className={`container `}>
          {breadcrumbs && (
            <div className="breadcrumbs-list">
              <div className="container">
                <ul className="breadcrumbs d-flex flex-wrap">
                  {breadcrumbs.map((item, idx) => (
                    <li className="me-2" key={idx}>
                      <a href={item.url}>{item.name}</a>{" "}
                      {item.url && <span>></span>}{" "}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          <div className={`wrapper-content `}>
            {title && <h3>{title}</h3>}
            {blurb && <span className="banner-blurb">{parse(blurb)}</span>}
          </div>
          {buttonText && (
            <a
              className="btn btn-primary btn-lg mb-4 banner-button"
              href={buttonLink}
              role="button"
              onClick={() => {
                window.analytics.track("clicked Banner button " + buttonText, {
                  title: "Banner Button",
                });
              }}
            >
              {buttonText}
            </a>
          )}
        </div>
      </div>
    </section>
  );
};

export default BannerUK;
