import { Row } from "react-bootstrap";
import CatalogItemCard from "../CatalogItemCard";
import LinksContainer from "./linksContainer";
/**
 *  @Component props.data:
 * title
 * componentContent
 * cards[]
 */
const UkListContainer = (props) => {
  return (
    props.data &&
    props.data.map((component, index) => (
      <Row
        key={index}
        className={
          component.className
            ? component.className + " component link-container"
            : "component link-container "
        }
      >
        {console.log("PROPS UKListContainer", props)}
        {component.title && (
          <h2 className="title d-flex justify-content-center py-3">
            {" "}
            {component.title}
          </h2>
        )}
        {component?.components?.map((item, idx) => (
          <div
            className={
              idx === 0
                ? "col-sm-12 col-md-12 col-lg-8"
                : "col-sm-12 col-md-12 col-lg-4"
            }
            key={idx}
          >
            <LinksContainer
              className="col-xs-12 col-lg-4 col-sm-6 "
              data={item}
            />
          </div>
        ))}
      </Row>
    ))
  );
};

export default UkListContainer;
